import { useQuery } from 'react-query';
import Client from '../index';
import Axios from '../../Utils/axios';

export const useGetAllEventsQuery = () => {
    const { data, isLoading, error } = useQuery(['all_events'], () => Client.events.all(), {
        onSuccess: (data) => {
        },
        onError: (err) => {
        }
    })

    return {
        allEvents: data?.data.data?.allEvents ?? [],
        error: error ?? null,
        isLoading
    }
}
export const useGetAlllEventsQuery = (page,usersPerPage) => {
    const { data, isLoading, error } = useQuery('all_events', async () => {
        const response = await Axios.get(`/event/all?page=${page}&pageSize=${usersPerPage}`);
        console.log(response)
        return response.data;
    });

    return {
        pageTotal: data?.data.pagination.pageTotal ?? [],
        allEvents: data?.data.allEvents ?? [],
        error: error ?? null,
        isLoading
    }
}

export const useGetFeaturedEventQuery = () => {
    const { data, isLoading, error } = useQuery(['featured_events'], () => Client.events.featured(), {
        onSuccess: (data) => {
        },
        onError: (err) => {
        }

    })
    return {
        featuredEvents: data?.data.data?.allEvents ?? [],
        error: error ?? null,
        isLoading
    }
}

export const useGetHeroEventQuery = () => {
    const { data, isLoading, error } = useQuery(['hero_event'], () => Client.events.hero(), {
        onSuccess: (data) => {
        },
        onError: (err) => {
        }

    })
    return {
        heroEvent: data?.data.data ?? null,
        error: error ?? null,
        isLoading
    }
}

export const useUpcomingEventQuery = () => {
    const { data, isLoading, error } = useQuery('upcoming_event', async () => {
        const response = await Axios.get('/event/upcoming');
        return response.data;
    });

    return {
        upcomingEvents: data?.data?.allEvents ?? [],
        error: error ?? null,
        isLoading
    };
};


export const paymentController = async (data) => {
    try {
        const response = await Axios.post(`/tickets/rsvp?deviceType=web`, data);
        return response?.data;
    } catch (error) {
        console.log(error)
        console.log(error?.response?.data);
        // error.response.status;
        throw new Error(`Sorry: ${error.response.data.message}`);
    }
};



export const getAnnouncement = async (data) => {
    try {
        const response = await Axios.post(`/announcement/all`, data);
        return response?.data;
    } catch (error) {
        console.log(error)
        console.log(error?.response?.data);
        // error.response.status;
        throw new Error(`Sorry: ${error.response.data.message}`);
    }
}