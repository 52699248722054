import React, { useImperativeHandle } from 'react';
import { Modal, Row, Col } from 'reactstrap';
import './index.css';
import coverImg from '../../Assets/img/eventDetails.png';
import QRCode from 'qrcode.react';
import locationIcon from '../../Assets/img/location.svg';
import timeIcon from '../../Assets/img/ticket_time.svg';
import calendarIcon from '../../Assets/img/tic_cal.svg';
import userIcon from '../../Assets/img/tic_user.svg';
import orderIcon from '../../Assets/img/tic_ord.svg';
import copyIcon from '../../Assets/img/tic_cop.svg';
import tagIcon from '../../Assets/img/tic_tag.svg';
import moment from 'moment';

const TicketDate = React.forwardRef((props, ref) => {
  const { data } = props;
  const ticketRef = ref;

  const imageUrls = data?.eventId?.media.filter((url) =>
    /\.(jpg|png|jpeg)$/.test(url)
  );

  const qrData = {
    shared: {
      state: data?.shared?.status,
      to: {
        ticketId: data?.shared?.to?.ticketId,
        fullName: data?.shared?.to?.fullName,
        email: data?.shared?.to?.email,
        phone: data?.shared?.to?.phone,
      },
    },
    purchasedBy: {
      fullName: data?.purchasedBy?.fullName,
      ticketId: data?.tId,
      email: data?.purchasedBy?.email,
      phone: data?.purchasedBy?.phone,
    },
  };

  const qrDataString = JSON.stringify(qrData);

  const copyToClipboard = (text) => {
    navigator.clipboard?.writeText(text).catch((err) => {
      // Fallback if clipboard API is not supported
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    });
  };

   

  return (
    <div className="ticket_scan-modal_wrapper px-3 px-md-0" ref={ticketRef} key={data?._id}>
      <div className="ticket_scan-modal position-relative" style={{ backgroundColor: 'black' }}>
        <div
          className="ticket-cover_img--container px-4"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.45) 0.09%, rgba(0, 0, 0, 1) 90.97%), url(${imageUrls?.[0] || coverImg})`,
          }}
        >
          <div className="qr-code__wrapper">
            <div className="qr-code__wrapper__container">
              <QRCode value={qrDataString} />
            </div>
          </div>

          <div className="d-flex align-items-center mb-3">
            <p className="event-ticket__type px-3 mb-0 me-1">
              {data?.eventId?.type?.name ?? 'N/A'} Event
            </p>
            <p className="event-ticket__category px-3 mb-0 text-capitalize">
              {data?.eventId?.category}
            </p>
          </div>
        </div>

        <div className="ticket-details__wrapper px-4">
          <h3 className="event-ticket__name">{data?.eventId?.name}</h3>

          <div className="d-flex mb-4">
            <div className="pe-2 ticket-icon__type">
              <img src={locationIcon} alt="Location" />
            </div>
            <div>
              <p className="mb-0 ticket-icon__name pt-1 mb-1">Location</p>
              <p className="mb-0 ticket-icon__value">{data?.eventId?.location}</p>
            </div>
          </div>

          <Row className="mb-4">
            <Col xs="6">
              <div className="d-flex">
                <div className="pe-2 ticket-icon__type">
                  <img src={timeIcon} alt="Time" />
                </div>
                <div>
                  <p className="mb-0 ticket-icon__name pt-1 mb-1">Time</p>
                  <p className="mb-0 ticket-icon__value">
                    {`${data?.eventId?.time?.start} - ${data?.eventId?.time?.end}`}
                  </p>
                </div>
              </div>
            </Col>
            <Col xs="6">
              <div className="d-flex">
                <div className="pe-2 ticket-icon__type">
                  <img src={calendarIcon} alt="Date" />
                </div>
                <div>
                  <p className="mb-0 ticket-icon__name pt-1 mb-1">Date</p>
                  <p className="mb-0 ticket-icon__value">
                    {moment(data?.eventId?.date).format('DD-MMM-YYYY')}
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xs="6">
              <div className="d-flex">
                <div className="pe-2 ticket-icon__type">
                  <img src={userIcon} alt="User" />
                </div>
                <div>
                  <p className="mb-0 ticket-icon__name pt-1 mb-1">Name</p>
                  <p className="mb-0 ticket-icon__value">{data?.purchasedBy?.fullName}</p>
                </div>
              </div>
            </Col>

            <Col xs="6">
              <div className="d-flex">
                <div className="pe-2 ticket-icon__type">
                  <img src={orderIcon} alt="Order ID" />
                </div>
                <div>
                  <p className="mb-0 ticket-icon__name pt-1 mb-1">Order ID</p>
                  <p className="mb-0 ticket-icon__value">
                    {data?.tId}{' '}
                    <span className="ps-2" onClick={() => copyToClipboard(data?.tId)}>
                      <img src={copyIcon} alt="Copy" />
                    </span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="ticket-border__line_wrapper px-4">
          <div className="ticket-border__line"></div>
        </div>

        <div className="ticket-pricing_wrapper px-4 pb-4 text-center pt-4">
          <div className="mb-3">
            <p className="mb-0 text-center ticket-pricing">
              <span className="pe-2">
                <img src={tagIcon} alt="Tag" />
              </span>
              Ticket: {data?.ticketId?.name}
            </p>
          </div>
          <div>
            <h3 className="ticket-pricing_value">
              {data?.ticketId?.price ? (
                <>
                  <span className="ticket_price_symbol pe-2">₦</span>
                  {data?.ticketId?.price?.toLocaleString()}
                </>
              ) : (
                'Free'
              )}
            </h3>
          </div>
        </div>

        <div className="ticket-card_disc left position-absolute"></div>
        <div className="ticket-card_disc right position-absolute"></div>
      </div>
    </div>
  );
});

export default TicketDate;
