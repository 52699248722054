import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Ticket from '../../Components/Ticket';
import Header from '../../Components/Header';
import Loader from '../../Components/Loader';
import Footer from '../../Components/AppLayout/Footer';
import download from '../../Assets/img/download.svg';
import jsPDF from 'jspdf';
import axios from '../../Utils/axios';
import html2canvas from 'html2canvas';

const ScanTicket = () => {
  const [ticketData, setTicketData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apiKey, setApiKey] = useState('');
  const ticketRef = useRef(null);
  const navigate = useNavigate();
  const { ticketId } = useParams();

  const downloadPDF = async () => {
    if (!ticketRef.current) {
      alert('Element not found or unable to generate PDF.');
      return;
    }

    const canvas = await html2canvas(ticketRef.current);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgWidth = 210; // A4 size
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    pdf.save(`tixrushTicket-${new Date().getDate()}.pdf`);
  };

  const fetchKey = async () => {
    try {
      const { data } = await axios.post(`/apiKey/generate`, {
        name: 'Deniyi Femi',
        email: 'holarfemilekan049@gmail.com',
      });
      setApiKey(data.data.key);
    } catch (err) {
      console.error('Error generating API key:', err);
    } finally {
      setLoading(false);
    }
  };

  const getTicketData = async () => {
    try {
      const response = await axios.get(`tickets/?_id=${ticketId}`, {
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        },
      });
      setTicketData(response.data.data.data[0]);
    } catch (err) {
      console.error('Error fetching ticket data:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ticketId) navigate('/');
    else fetchKey();
  }, [ticketId, navigate]);

  useEffect(() => {
    if (apiKey) getTicketData();
  }, [apiKey]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header position="fixed" activeLink="" subPage={false} />
          <div className="scan-ticket__wrapper">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="px-2" style={{ fontSize: 20, fontWeight: 600, color: '#fff', margin: '10px 0' }}>
                My Ticket
              </h3>
              <div className="d-flex" style={{ color: '#00E194', cursor: 'pointer' }} onClick={downloadPDF}>
                <img className="px-1" src={download} alt="Download" />
                <span>Download Ticket</span>
              </div>
            </div>
            <Ticket ref={ticketRef} data={ticketData} />
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default ScanTicket;
