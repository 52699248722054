import { useState } from "react";
import  svg_leftarrowicon from "../Assets/svg/leftarrowicon.svg" 
import svg_rightarrowicon  from "../Assets/svg/rightarrowicon.svg";

const Pagination = ({ currentPage, pageTotal, onChange }) => {
  const [currentPagee, setCurrentPage] = useState(currentPage);
console.log(pageTotal)
  const handlePageChange = (page) => {
    console.log(page)
    window.scrollTo(0, -10);
    setCurrentPage(page);
    onChange(page);
  };

  let startPage = currentPagee - 1;
  let endPage = currentPagee + 1;

  // Adjust start and end page if they exceed the page range
  if (startPage < 1) {
    startPage = 1;
    endPage = Math.min(pageTotal, startPage + 2);
  }
  if (endPage > pageTotal) {
    endPage = pageTotal;
    startPage = Math.max(1, endPage - 2);
  }

  return (
    <div className="bg-transparent d-flex flex-shrink-0  flex-row justify-content-between align-items-center" style={{height: "30px", width: "187px"}}>
      <button
        className="bg-transparent border-0 outline-none d-flex justify-content-between align-items-center"
        disabled={currentPagee === 1}
        onClick={() => handlePageChange(currentPagee - 1)}
        style={{
            width: "26.22px",
            height: "26.22px",

        }}
      >
        <img src={svg_leftarrowicon} alt="arrow" style={{width: "20.43px", height: "20.43px"}} />
      </button>
      {[...Array(endPage - startPage + 1)].map((_, index) => {
        const pageNumber = startPage + index;
        const isLastPage = pageNumber === endPage;
        const currentPageee = currentPage === pageNumber;
        console.log(isLastPage)
        return (
        <button
          key={pageNumber}
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: isLastPage ? "#A9A9A9" : "#FFFFFF",
            border: currentPageee ? "0" : " solid 1.38px ",
            borderColor: isLastPage ? "#A9A9A9" : "#FFFFFF",
            backgroundColor: currentPageee ? "#00E194" : "transparent",
            
          }}
          className={` outline-none `}
          onClick={() => handlePageChange(pageNumber)}
        >
          <h6 className={`m-0 ${
            currentPage === pageNumber ? 'text-black ' : ''
          }`}
          style={{
            color: isLastPage ? "#A9A9A9" : "#FFFFFF",
          }}
          >{pageNumber}</h6>
        </button>)
      })}
      <button
        className="bg-transparent border-0 outline-none d-flex justify-content-between align-items-center"
        disabled={currentPagee === pageTotal}
        onClick={() => handlePageChange(currentPagee + 1)}
        style={{
            width: "26.22px",
            height: "26.22px",

        }}
      >
        <img src={svg_rightarrowicon} alt="arrow" style={{width: "20.43px", height: "20.43px"}}  />
      </button>
    </div>
  );
};

export default Pagination;

